import React from 'react';
// import logo from './logo.svg';
import './App.css';
// import { Router } from "react-router";
import {   BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { InfoForm } from './Tform';

function App() {
  return (

    <div>
      <Router>
        <Routes>

          <Route path={"/404"} element={<div/>} />
          <Route path={"/redirect_duitnow"} element={<div>Duitnow Success</div>} />
          <Route path={"/:target"} element={<InfoForm/>} />
          <Route path={"/"} element={<div/>} />
        </Routes>
        
      </Router>
    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={"/"} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
