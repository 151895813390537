import React, { useState } from 'react'
import {loadStripe} from '@stripe/stripe-js';
import { useNavigate, useParams } from 'react-router';
import "./tform.css"

// const stripe = await loadStripe('pk_live_uKsA0CBTwhepIbqT4ZXdOgO8');
// const pk_key = 'pk_test_sCc5brCJj5mhWdQosZaJZMp8'
const pk_key = "pk_live_uKsA0CBTwhepIbqT4ZXdOgO8"
type redirectProps = {
    pk_key:string ,  
    price_plan:string,  
    subscription? :boolean,
    customerEmail : string
}    
async function redirect( props : redirectProps ) {
    const stripe = await loadStripe( props.pk_key );
    stripe!.redirectToCheckout({
        lineItems : [{price: props.price_plan , quantity:1}],
        mode : props.subscription ? "subscription" : "payment",
        customerEmail : props.customerEmail,
        successUrl: 'https://www.rainsoln.com/success',
        cancelUrl: 'https://www.rainsoln.com/canceled',
    })
}


function validateEmail(email:string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
type Props = {
    message? : string,
    title? : string
}

const StripePricePlan = {
    "Pro" : "price_0HxOhk5ndaCxFyAQWn7lllxe",
    "Standard" : "price_0HxOhk5ndaCxFyAQcvwZcVv6",
    // snippet
    "Premium" : "price_0HxOcX5ndaCxFyAQXzf291kC"
}
const StripeSubscriptionPricePlan = {
    "Pro" : "plan_H4M3WXn1pL3U5d",
    "Standard" : "plan_HEzER1gyPCJ9Ka",
    // snippet
    "Premium" : "price_0HxOcX5ndaCxFyAQcPlDRxOD"
}

const PriceList = {
    "Pro" : "18",
    "Standard" : "12",
    "Premium" : "21"
}
export const InfoForm: React.FC<Props> = ({message, title })=>{
    // if (!show ) return null
    // const { handleSubmit, register, errors } = useForm<formdata>();
    let params = new URLSearchParams(window.location.search)
    const [ customerEmail , setCustomerEmail ] = useState("")
    let plan : string = params.get("plan") || "" 
    const history = useNavigate()
    const {target} = useParams<{target:string}>()

    console.log(target)

    function isValid(value: string): value is keyof typeof StripePricePlan {
        return value in StripePricePlan;
    }
    let price_plan =""
    let sub_price_plan =""
    let price = ""
    if (isValid(plan) ) {
        price_plan = StripePricePlan[plan]
        sub_price_plan = StripeSubscriptionPricePlan[plan]
        price = PriceList[plan]
    }else {
        console.log("Invalid plan")
        history("/")
    } 

    console.log()
    return (
        <div className="popup_area alert_pop_stamp_cancel" style={{display:'block'}}>
            <div className="dimmed" />
            <div className="popup cancel_pop">

                <div className="popup_con stamp_cancel_popup_con popup_infobox">
                    <h1>{target?.toLocaleUpperCase()}</h1>
                    <h1 style={{marginBottom:'1rem'}}> { plan } Plan </h1>

                    <h2 style={{margin:"0px"}}>  {price} USD </h2>
                    
                    <span style={{marginTop:0, marginBottom:"1rem", fontSize:"0.8rem", lineHeight:"0.8rem"}}>
                        Charge bi-annually (per 6 months)</span>


                    <p>Please key in your *gmail for the subscription</p>

                <form onSubmit={(event)=> event.preventDefault() }>
                    <input
                        className="form-control input_email"
                        name="email"
                        placeholder="Gmail address"
                        type="email"
                        onChange={(e)=>setCustomerEmail(e.target.value)}
                        required 
                    />
                    {/* {errors.email 
                        && <div> errors.email!.message</div>} */}
                    <div className="btn-div">
                    <div> Auto Renewal </div>
                    <button 
                        className="btn btn-primary" 
                        type="submit" 
                        onClick={()=>{

                            if ( validateEmail(customerEmail) ) {
                                redirect({
                                    price_plan : sub_price_plan,
                                    subscription : true,
                                    customerEmail,
                                    pk_key : pk_key,
                                    
                                })

                            }
                        }}>
                        Auto Renewal with <strong>Stripe</strong> Checkout</button>

                    </div>

                    <div className="btn-div">
                        <div>One Time Subscription of 6 months</div>

                        <button 
                        className="btn btn-primary " 
                        type="submit" 
                        onClick={()=>{

                            if ( validateEmail(customerEmail) ) {
                                redirect({
                                    price_plan : price_plan,
                                    subscription : false,
                                    customerEmail,
                                    pk_key : pk_key,
                                    
                                })

                            }
                        }}>
                        Pay with <strong>Stripe</strong> Checkout</button>

                        <h5 className="marginTop20">Crypto</h5>
                        <button className="btn btn-secondary" disabled>
                            Coming Soon
                        </button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default InfoForm